import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import disc from '../../data/dyskografia.json'

import { Navigation } from "swiper";

function Dyskografia() {
    return (
        <div className='subpage-container' style={{
            backgroundImage: `url("img/background-7-min.jpg")`,
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{flex: 1}}>
                <div className='subpage-header'>
                    Dyskografia
                </div>
                <div className='subpage-content' style={{ position: 'relative', display: 'flex', alignItems: 'stretch' }}>
                    <Swiper 
                        navigation={true} 
                        modules={[Navigation]} 
                        className="mySwiper" 
                        style={{
                            height: '100%'
                        }}
                    >
                        {disc.dyskografia.map((album, index)=>{
                            return <SwiperSlide>
                                <div className='album-card'>
                                    <div className='album-main-flex' style={{display: 'flex', alignItems:'strech'}}>
                                        <div className='albumcoverContainer' style={{flex: 1}}>
                                            <img src={`/img/album_covers/${album.cover}`}/>
                                        </div>
                                        <div 
                                            className='album-details'
                                            style={{
                                                flex: 1,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                // height: '100%'
                                            }} 
                                        >
                                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                                                <div>
                                                    <div style={{fontSize: '1.3em', marginBottom: '20px'}}>{album.title}</div>
                                                </div>
                                                <div>
                                                    {album.tracklist.map((track, index)=>{
                                                        return <div>{index + 1}. {track}</div>
                                                    })}
                                                </div>
                                                {
                                                    album.spotify_link !== ''
                                                    ?
                                                    <div className='spotifyLink' 
                                                        style={{
                                                            display: 'flex', 
                                                            alignItems: 'center', 
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        Listen on Spotify 
                                                        <img 
                                                            id='spotify-icon' 
                                                            src='/img/icons/iconmonstr-spotify-1-240.png' 
                                                            width='60px' 
                                                            onClick={()=>{
                                                                window.open(album.spotify_link)
                                                            }}
                                                        >
                                                        </img>
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Dyskografia


{/* <Carousel 
itemsToShow={1}
showThumbs={false}
style={{
    height: '64vh',
    justifyContent: 'center'
}}
>
{disc.dyskografia.map((album) => {
    return <div className='discography-single-container'>
        <div style={{flex: 2}} className='album-cover-container'>
            <img src={`/img/album_covers/${album.cover}`} className='album-cover' />
        </div>
        <div 
            className='album-details'
            style={{
                flex: 3,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }} 
        >
            <div 
                style={{
                    
                }}
            >
                <div className='album-title'>
                    <h2>{album.title}</h2>
                </div>
                <div className='tracklist'>
                    {album.tracklist.map((track, index)=>{
                        return <div>{index + 1}. {track}</div>
                    })}
                </div>
                <div>Spotify Link</div>
            </div>
        </div>
    </div>
})}
</Carousel> */}
