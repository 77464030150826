import React from 'react'

function MainMenu({refs}) {
    return (
        <div id='menu-top-bar'>
            <div className='menu-top-bar-horizontal-line'><hr /></div>
            <div className='menu-top-bar-button'
                onClick={()=>{
                    refs.StronaGlownaRef.current.scrollIntoView()
                }}>
                    Strona Główna
            </div>
            <div className='menu-top-bar-horizontal-line'><hr /></div>
            <div className='menu-top-bar-button'
                onClick={()=>{
                    refs.BiografiaRef.current.scrollIntoView()
                }}>
                    Biografia
            </div>
            <div className='menu-top-bar-horizontal-line'><hr /></div>
            <div className='menu-top-bar-button'
                onClick={()=>{
                    refs.MediaRef.current.scrollIntoView()
                }}>
                    Media
            </div>
            <div className='menu-top-bar-horizontal-line'><hr /></div>
            <div className='menu-top-bar-button'
                onClick={()=>{
                    refs.DyskografiaRef.current.scrollIntoView()
                }}>
                    Dyskografia
            </div>
            <div className='menu-top-bar-horizontal-line'><hr /></div>
            <div className='menu-top-bar-button'
                onClick={()=>{
                    refs.GaleriaRef.current.scrollIntoView()
                }}>
                    Galeria
            </div>
            <div className='menu-top-bar-horizontal-line'><hr /></div>
            <div className='menu-top-bar-button'
                onClick={()=>{
                    refs.KontaktRef.current.scrollIntoView()
                }}>
                    Kontakt
            </div>
            <div className='menu-top-bar-horizontal-line'><hr /></div>
        </div>
    )
}

export default MainMenu
