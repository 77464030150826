import './App.css';
import React, { useState, useRef } from 'react';
import { useWindowDimensions } from './utils/utils';

import MainMenu from './components/MainMenu';
import StronaGlowna from './components/subpages/StronaGlowna';
import Biografia from './components/subpages/Biografia';
import Media from './components/subpages/Media';
import Dyskografia from './components/subpages/Dyskografia';
import Galeria from './components/subpages/Galeria';
import Kontakt from './components/subpages/Kontakt';

function App() {
  const StronaGlownaRef = useRef(null)
  const BiografiaRef = useRef(null)
  const MediaRef = useRef(null)
  const DyskografiaRef = useRef(null)
  const GaleriaRef = useRef(null)
  const KontaktRef = useRef(null)

  const refs = { StronaGlownaRef, BiografiaRef, MediaRef, DyskografiaRef, GaleriaRef, KontaktRef }

  return (
    <div className="App">
      <div>
        <MainMenu refs={refs}></MainMenu>
      </div>
      <div className='sections-container'>
        <div ref={refs.StronaGlownaRef}>
          <StronaGlowna />
        </div>
        <div ref={refs.BiografiaRef}>
          <Biografia />
        </div>
        <div ref={refs.MediaRef}>
          <Media />
        </div>
        <div ref={refs.DyskografiaRef}>
          <Dyskografia />
        </div>
        <div ref={refs.GaleriaRef}>
          <Galeria />
        </div>
        <div ref={refs.KontaktRef}>
          <Kontakt />
        </div>
      </div>

      <div
        className='button-kup-album'
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 1200,
          borderRadius: '10px',
          color: 'white',
          padding: '15px 15px 10px 15px',
          boxShadow: 'inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)'
        }}
        onClick={()=>{window.open('https://allegrolokalnie.pl/oferta/dominika-barabas-psia-mac-cd')}}
      >
        <div>
          <img src='img/cover_full2-small.jpg'/>
        </div>
        <div style={{marginTop: '5px'}}>
          Kup album!
        </div>
      </div>
    </div>
  );
}

export default App;
