import React from 'react'

import media from '../../data/media.json'

function Media() {
    return (
        <div className='subpage-container' style={{
            backgroundImage: `url("img/background-6-min.jpg")`
        }}>
            <div className='subpage-header'>
                Media
            </div>
            <div className='subpage-content' style={{
                textAlign: 'center'
            }}>
                {media.media.map((link, index) => {
                    return <iframe className="media-video" key={index} src={link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                })}
            </div>
        </div>
    )
}

export default Media
