import React from 'react'

function StronaGlowna() {
    return (
        <div className='subpage-container' style={{
                backgroundImage: `url("img/background_4-min.jpg")`,
                justifyContent: 'space-between'
            }}>
            <img src='/img/cover_only_head_small.png' className='head'/>
            <div className='main-page-header'>
                <h1>
                    Dominika Barabas
                </h1>
            </div>    
            <div className='social-media-icons-container'>
                <div className='social-media-icon'>
                    <a href="https://www.facebook.com/barabasofficial" target="_blank">
                        <img src='/img/icons/iconmonstr-facebook-6.svg' />
                    </a>
                </div>
                <div className='social-media-icon'>
                    <a href="https://www.instagram.com/dominika_barabas/" target="_blank">
                        <img src='/img/icons/iconmonstr-instagram-13.svg' />
                    </a>
                </div>
                <div className='social-media-icon'>
                    <a href="https://www.youtube.com/@Dominikabarabasinfo" target="_blank">
                        <img src='/img/icons/iconmonstr-youtube-8.svg' />    
                    </a>
                </div>
            </div>
        </div>
    )
}

export default StronaGlowna
