import React from 'react'

function Biografia() {
    return (
        <div className='subpage-container' style={{
            backgroundImage: `url("img/background2-min.jpg")`
        }}>
            <div className='subpage-header'>
                Biografia
            </div>
            <div className='subpage-content'>
                <p>
                    Kompozytorka, aranżerka, autorka tekstów, producentka, multiinstrumentalistka i wokalistka. Artystka nieuchwytna i niepokorna; nieustannie zaskakująca twórczyni ekscentrycznych teledysków i magicznych stylizacji.<br/>
                    Laureatka głównych nagród festiwali o zasięgu ogólnopolskim i międzynarodowym, m.in.:  Studencki Festiwal Piosenki , Międzynarodowy Festiwal OPPA, PPA , Niemen NON STOP, Przebojem na Antenę, Pejzaż bez Ciebie, Spotkania Młodych Autorów i Kompozytorów SMAK, Pamiętajmy o Osieckiej. Artystka zaznaczyła również swoją sceniczną obecność na wielu prestiżowych festiwalach muzycznych, jak m.in: SoundEdit Festival, Krajowy Festiwal Piosenki Polskiej w Opolu, Męskie Granie, Spring Break, Festiwal Jazz Jantar, TransVocale. Jest także autorką muzyki do gry komputerowej Two Worlds II: EotDP Memories, organizatorką międzynarodowych warsztatów kompozytorskich Songwriting Camp Poland.
                </p>
                <p>
                    ALBUMY AUTORSKIE<br/>
                    • Cień (wyd. Ballada)<br/>
                    • Zbiór porannych bzdur (wyd. Universal Music Polska)<br/>
                    • Rustykalny Cyrk (wyd. Agencja Muzyczna Polskiego Radia)<br/><br/>

                    KOMPILACJE PŁYTOWE<br/>
                    • Agnieszka Osiecka zaśpiewana (wyd. MTJ Agencja Artystyczna) • Muzyka Czterech Stron Świata (wyd.MTJ Agencja Artystyczna)<br/>
                    • Mistrzowie Piosenki (wyd. Warner Music Poland)<br/>
                    • Pamiętajmy o Osieckiej" (wyd. MTJ Agencja Artystyczna)<br/>
                    • Ballady i niuanse (wyd. Warner Music Poland)<br/>
                    • Między ciszą a ciszą (wyd. Universal Music Polska)<br/>
                    • W stronę Krainy Łagodności Vol.1" (wyd. Warner Music Poland)<br/><br/>

                    SINGLE<br/>
                    • Zbiór porannych bzdur (wyd. własne)<br/>
                    • Może mi być ciężko umierać (wyd. Universal Music Polska)<br/>
                    • Idiotka (wyd. HQT Music Group) • Andy (wyd. Agencja Muzyczna Polskiego Radia) • O.O. (wyd. Agencja Muzyczna Polskiego Radia)<br/>
                    • Przepraszam (wyd. Agencja Muzyczna Polskiego Radia)<br/>
                    • Tylko mnie kochaj (wyd. własne)<br/>
                    • Odturlaj (wyd. własne)<br/>
                </p>
            </div>
        </div>
    )
}

export default Biografia
