import React from 'react'

function Kontakt() {
    return (
        <div className='subpage-container' style={{
            backgroundColor: `black`,
        }}>
            <div className='subpage-header'>
                Kontakt
            </div>
            <div className='subpage-content' style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
                {/* <div>Joanna Weber - manager</div> */}
                <div>email: dominikabarabasofficial@gmail.com</div>
                <div>tel. 501 358 363</div>
                {/* <br/><br/>
                <div>Oferta koncertowa:</div>
                <div>
                    <a href="/oferta/Oferta-Koncertowa-Dominika-Barabas.pdf" download="Dominika-Barabas-Oferta-Koncertowa.pdf">
                        <button id='oferta-pobierz-button'>Pobierz</button>
                    </a>
                </div> */}
            </div>
        </div>
    )
}

export default Kontakt
