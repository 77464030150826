import React from 'react'

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import galeria from '../../data/galeria.json'

import { Navigation } from "swiper";

function Galeria() {
    return (
        <div className='subpage-container' style={{
            backgroundImage: `url("img/background_4-min.jpg")`,
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{flex: 1}}>
                <div className='subpage-header'>
                    Galeria
                </div>
                <div className='subpage-content' style={{ position: 'relative', display: 'flex', alignItems: 'stretch' }}>
                    <Swiper 
                        navigation={true} 
                        modules={[Navigation]} 
                        className="mySwiper" 
                        style={{
                            height: '100%'
                        }}
                    >
                        {galeria.galeria.map((image, index)=>{
                            return <SwiperSlide style={{height: '100%'}}>
                                <div 
                                    style={{
                                        height: '65vh',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <div className='album-card' style={{textAlign: 'center'}}>
                                            <img 
                                                src={`/img/galeria/small/${image}`} 
                                                style={{
                                                    height: '100%',
                                                    maxWidth: '100%',
                                                    marginLeft: 'auto', 
                                                    marginRight: 'auto'
                                                }}
                                            />
                                    </div>
                                    <div style={{marginTop: '10px'}}>
                                        <button 
                                            className='image_download_button'
                                            style={{
                                                borderRadius: '15px',
                                                border: '1px solid black',
                                                height: '30px',
                                                paddingLeft: '20px',
                                                paddingRight: '20px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={()=>{window.open(`/img/galeria/large/${image}`)}}
                                        >
                                            Pobierz
                                        </button>
                                    </div>
                                </div>
                            </SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Galeria
